import { $order,$getSalesPrice,$orderConfirm,$payment,$ekaf,$paymentQuery,$paymentWechatconf } from "../../api/home_marriage_test"
import { $stat } from "../../api/stat"
const payment = {
    data () {
        return {
            complaint_buttom_fal:true, //投诉按钮开关
            popup_text:'领取优惠红包',
            percentage_num:0.8,
            counttime:10*60*1000,//倒计时
            fixedbtn:false, //是否隐藏底部付费解锁全部内容按钮
            userinfo:{},//用户信息
            name:'',
            price:{},//价格
            time:localStorage.getItem("time"),//计时器
            data_list:[], //动态用户列表数据
            shopPayConfirm: false,//请确认支付是否完成弹窗
            // shopPayConfirm: true,//请确认支付是否完成弹窗
            type:localStorage.getItem("type"),
            is_pay:"",//是否支付
            wechat_pay_key:1,
            
        }
    },
    created () {
        if ( localStorage.getItem('red_packet_type') === '2' ) {
            this.popup_text = '测试双方契合度';
            localStorage.setItem("judge_indicates_which",1);
        }else{
            this.popup_text = '领取优惠红包'
        }
        if ( localStorage.getItem('red_packet_type') === null) {
           
        }else{
            this.percentage_num = 0.7
        }
        // console.log('ada',arr_from_.indexOf(this.$route.query.from))
        this.dataRequest()
        this.confirmPaymentPopup() 
        window.addEventListener("scroll", this.handleScroll);
        sessionStorage.setItem("user_id",this.$route.params.id);
        // console.log('/'+this.$route.path.split('/')[1])
    },
    mounted () {
        if (window.history && window.history.pushState) {
            // 向历史记录中插入了当前页
            history.pushState(null, null, document.URL);
            window.addEventListener('popstate', this.goBack, false);
        }
        this.getWxConfig()
        // $stat("v1_marry_pay");
        switch ('/'+this.$route.path.split('/')[1]) { //埋点
            case '/home_marriage_test':
                $stat("v1_marry_pay")
                break; 
            case '/home_marriage_test_v2':
                $stat("v2_marry_payment")
                break;
            case '/home_marriage_test_v3':
                $stat("v3_marry_payment")
                break;
        
            default:
                break;
        }
        this.confirmPaymentPopup() 
    },
    computed: {
        is_weixn() {
            var ua = navigator.userAgent.toLowerCase();
            if (ua.match(/MicroMessenger/i) == "micromessenger") {
                return true;
            } else {
                return false;
            }
        },
        showwechat(){//隐藏微信支付
            let from = this.$route.query.from;
            // let allowfrom = ['xq3','xp1'];
            let allowfrom = ['',''];
            if (allowfrom.indexOf(from) > -1) {
                return true
            } else {
                return false
            }
        }
    },
    methods:{
        goBack () {//后退事件
            // console.log("点击了浏览器的返回按钮");
            this.$router.push('/'+this.$route.path.split('/')[1])
            // this.$router.push('/')

            // this.$router.go(0)
            // sessionStorage.clear();
            // window.history.back();
        },
        getWxConfig(){//微信内支付配置
            // let url = window.location.href;
                $paymentWechatconf({url:window.location.href}).then(res => {
                    // console.log('wx',res.data.data.data)
                    let wxconfig = res.data.data.data;
                    if (this.is_weixn) {
                        wx.config({
                            debug: false,
                            appId: wxconfig.appId,
                            timestamp: wxconfig.timestamp,
                            nonceStr: wxconfig.nonceStr,
                            signature: wxconfig.signature,
                            jsApiList: ['chooseWXPay']
                        });
                    }
                })
				// let data = await axios.post("/payment/wechatconf", {
				// 	url
				// })
				// return data.data.data.data;
        },
        saveTheLinkCallback() { // 三次挽回链接
            if (this.is_weixn) {
                let from_arr_ = ['dbhy','xfhy','xfhy2','zbhy','rmhy1','rmhy2','dghy'];
                let from_arr1_ = ['lndbgd','lnxf1','lnxf3','lnrt1','lnrt2'];
                switch ( -1 ) {
                    case from_arr_.indexOf(this.$route.query.from) === -1 ? from_arr_.indexOf(this.$route.query.from)+1 : from_arr_.indexOf(this.$route.query.from) - (from_arr_.indexOf(this.$route.query.from) + 1) :
                            window.location.replace(`https://jx2.gyqingqing.com/wechatmatch?fromk=pjgw3cm`)
                        break;
                    case from_arr1_.indexOf(this.$route.query.from) === -1 ? from_arr1_.indexOf(this.$route.query.from)+1 : from_arr1_.indexOf(this.$route.query.from) - (from_arr1_.indexOf(this.$route.query.from) + 1) :
                            window.location.replace(`https://jx2.gyqingqing.com/wechatmatch?fromk=ljgw3cm`)
                        break;
                
                    default:
                        window.location.replace(`https://wechat.nnabc.cn/api/v1/wechat/entry/${localStorage.getItem("fromk")}/3`)
                        break;
                }
                    
            }else{
                window.location.replace(`https://huyin.zhiyileiju.cn/name_matching?from=w3pd`)
            }
        },
        confirmPaymentPopup() {//通过接口判断确认支付弹框
            setTimeout(() => {
                if ( localStorage.getItem("type") !== '10' ){
                    // this.shopPayConfirm = true;//确认支付弹框关闭
                    if (this.is_weixn) {
                        if ( this.wechat_pay_key === 1 ) {
                            this.shopPayConfirm = false
                        }else{
                            this.shopPayConfirm = true
                        }
                    }else{
                        this.shopPayConfirm = true;//确认支付弹框打开
                    }
                    $paymentQuery({ paytype: localStorage.getItem("type"),order_id: this.$route.params.id}).then( (res) => {
                        this.is_pay = res.data.data.is_pay
                        if( res.data.data.is_pay ) {//判断用户已支付打开弹窗，跳转支付成页
                            this.shopPayConfirm = true;//确认支付弹框关闭

                            let arr_from_ = ['hytonp1','nptohy1','kppd','cdpd','kphy','cdhy']
                            if( arr_from_.indexOf(this.$route.query.from) === -1 ) {
                                window.location.replace(`/home_marriage_test/payment/succeed/${this.$route.params.id}/${localStorage.getItem("type")}?from=${this.$route.query.from}`)
                            }else{
                                //直接跳结果
                                window.location.replace(`/home_marriage_test/payment/result/${this.$route.params.id}/${localStorage.getItem("type")}?from=${this.$route.query.from}`)
                            }
                        }else{//用户没支付
                            if ( this.type == 10 ) { //支付方式
                                this.shopPayConfirm = false;//确认支付弹框关闭
                            }else{
                                if (this.is_weixn) {
                                    if ( this.wechat_pay_key === 1 ) {
                                        this.shopPayConfirm = false
                                    }else{
                                        this.shopPayConfirm = true
                                    }
                                }else{
                                    this.shopPayConfirm = true;//确认支付弹框打开
                                }
                               
                            }
                        }
                    } );   
                }
            }, 1000);
        },
        backToTheTopCallback (type) {//锚点
            // $stat("v1_marry_pay_bottom");
            if ( type === 'anchor') {
                switch ('/'+this.$route.path.split('/')[1]) { //埋点
                    case '/home_marriage_test':
                        $stat("v1_marry_pay_bottom")
                        break; 
                    case '/home_marriage_test_v2':
                        $stat("v2_marry_payment_anchor")
                        break;
                    case '/home_marriage_test_v3':
                        $stat("v3_marry_payment_anchor")
                        break;
                
                    default:
                        break;
                }
            }else{
                switch ('/'+this.$route.path.split('/')[1]) { //埋点
                    case '/home_marriage_test':
                        $stat("v1_marry_payment_unlock")
                        break; 
                    case '/home_marriage_test_v2':
                        $stat("v2_marry_payment_unlock")
                        break;
                    case '/home_marriage_test_v3':
                        $stat("v3_marry_payment_unlock")
                        break;
                
                    default:
                        break;
                }
            }
            document.querySelector("#agentTitle").scrollIntoView({behavior: "smooth", block: "start" });
        },
        handleScroll() {//y页面向下拉300时显示按钮
			var scrollTop =
				window.pageYOffset ||
				document.documentElement.scrollTop ||
				document.body.scrollTop;
			if (scrollTop > 300) {
				this.fixedbtn = true;
			} else {
				this.fixedbtn = false;
			}
		},
        dataRequest () {//数据请求
            $order(this.$route.params.id).then( ( res ) => {//获取用户信息
                this.userinfo = res.data.data
                this.name = res.data.data.hanzimean.xm
                let date = res.data.data.pub_birth_date
                this.userinfo.time_of_birth = `${date.substr(0,4)}年${date.substr(5,2)}月${date.substr(8,2)}日${date.substr(11,2)}时`
            }) 
            let postdata = {//$ekaf接口参数
                type: 2,
                perPage: 15,
                page: 1,
                atype:2,
                fromk:this.$route.query.from
            }
            $getSalesPrice({fromk:this.$route.query.from}).then( ( res ) => {//获取价格
                this.price = res.data.data[0]
            })
            setTimeout(() => {
                $ekaf(postdata).then( ( res ) => { //支付信息接口
                if ( res.status === 200 ){
                    this.data_list = res.data.data
                    for ( let i = 0; i < this.data_list.length; i++ ) {
                        this.data_list[i].price = this.price.marriage_amount 
                        this.data_list[i].name = (this.data_list[i].user).slice(0,-4)
                        this.data_list[i].designation = (this.data_list[i].user).substr(-4,4)
                    }
                    this.timimgToast()
                }
            })
            })
        },
        timeTrigger (TimeData) {//计时器变化触发(支付倒计时)
            //每次触发计时器后存到本地储存里，保证页面刷新计时器不重新开始
            let MM = TimeData.minutes * 60
            let ss = TimeData.seconds + MM
            let mss_ = TimeData.milliseconds + (ss*1000)
            localStorage.setItem("time",mss_);
        },
        paymentButtom (payment_type) {//支付
            if ( payment_type === '' ) {
                payment_type = localStorage.getItem("type") === '2' ? 'wechat' : 'alipay'
            }else{
                if( payment_type === 'alipay') {//埋点
                    switch ('/'+this.$route.path.split('/')[1]) { //埋点
                        case '/home_marriage_test':
                            $stat("v1_marry_pay_alipay")
                            break; 
                        case '/home_marriage_test_v2':
                            $stat("v2_marry_payment_alipay")
                            break;
                        case '/home_marriage_test_v3':
                            $stat("v3_marry_payment_alipay")
                            break;
                    
                        default:
                            break;
                    }
                }else if ( payment_type === 'wechat' ){
                    switch ('/'+this.$route.path.split('/')[1]) { //埋点
                        case '/home_marriage_test':
                            $stat("v1_marry_pay_wechat")
                            break; 
                        case '/home_marriage_test_v2':
                            $stat("v2_marry_payment_wechat")
                            break;
                        case '/home_marriage_test_v3':
                            $stat("v3_marry_payment_wechat")
                            break;
                    
                        default:
                            break;
                    }
                }
            }

            localStorage.setItem("type",payment_type === "alipay" ? 1 : 2); //将支付类型存到本地
            if ( this.type == 10 ) {
                this.shopPayConfirm = false;
            }else{
                if ( this.wechat_pay_key === 1 ) {
                    this.shopPayConfirm = false
                }else{
                    this.shopPayConfirm = true
                }
                // this.shopPayConfirm = true;
            }
            let setInterval__ =   setInterval(() => {
                 $paymentQuery({ paytype: localStorage.getItem("type"),order_id: this.$route.params.id,}).then( (res) => {
                    this.is_pay = res.data.data.is_pay
                    if( res.data.data.is_pay ) {
                        setTimeout( () => {this.shopPayConfirm = true;},2000)
                        clearInterval(setInterval__)
                        let arr_from_ = ['hytonp1','nptohy1','kppd','cdpd','kphy','cdhy']
                        if( arr_from_.indexOf(this.$route.query.from) === -1 ) {
                            window.location.replace(`/home_marriage_test/payment/succeed/${this.$route.params.id}/${localStorage.getItem("type")}?from=${this.$route.query.from}`)

                        }else{
                            //直接跳结果
                            window.location.replace(`/home_marriage_test/payment/result/${this.$route.params.id}/${localStorage.getItem("type")}?from=${this.$route.query.from}`)
                        }
                    }else{
                        
                    }
                } )
            },1000)
            if (
                this.userinfo.order_status == "已完成" ||
                this.userinfo.order_status == "已支付"
            ) {
                this.$dialog.confirm({
                    message: "当前订单已支付",
                    showCancelButton: false,
                }).then(() => {
                    let arr_from_ = ['hytonp1','nptohy1','kppd','cdpd','kphy','cdhy']
                    if( arr_from_.indexOf(this.$route.query.from) === -1 ) {
                        window.location.replace(`/home_marriage_test/payment/succeed/${this.$route.params.id}/${localStorage.getItem("type")}?from=${this.$route.query.from}`)
                    }else{
                        //直接跳结果
                        window.location.replace(`/home_marriage_test/payment/result/${this.$route.params.id}/${localStorage.getItem("type")}?from=${this.$route.query.from}`)
                    }
                    // window.location.replace(`/home_marriage_test/payment/succeed/${this.$route.params.id}/${localStorage.getItem("type")}?from=${this.$route.query.from}`)
                });
                return;
            }
            let data_ = { 
                    order_id:this.$route.params.id,
                    paytype: payment_type === "alipay" ? 1 : 2,
                }
                if ( localStorage.getItem('red_packet_type') === null) {
                    data_ = { 
                        order_id:this.$route.params.id,
                        paytype: payment_type === "alipay" ? 1 : 2,
                    }
                }else{
                    data_. discount_id =  1;
                    data_.discount_type = localStorage.getItem('red_packet_type');
                }
               
            $orderConfirm(data_).then( ( res ) => {
                if ( res.status === 200) {
                     if (this.is_weixn) {
					    $payment(localStorage.getItem("is_hun_yin_").substr(1),this.$route.params.id).then( ( res ) => {
                            let pay = res.data.data;
                            wx.ready(function() {
                                wx.chooseWXPay({
                                    timestamp: pay.timeStamp,
                                    nonceStr: pay.nonceStr,
                                    package: pay.package,
                                    signType: pay.signType,
                                    paySign: pay.paySign, // 支付签名
                                    success: function(res) {
                                        let arr_from_ = ['hytonp1','nptohy1','kppd','cdpd','kphy','cdhy']
                                        if( arr_from_.indexOf(this.$route.query.from) === -1 ) {
                                            window.location.replace(`/home_marriage_test/payment/succeed/${this.$route.params.id}/${localStorage.getItem("type")}?from=${this.$route.query.from}`)
                                        }else{
                                            //直接跳结果
                                            window.location.replace(`/home_marriage_test/payment/result/${this.$route.params.id}/${localStorage.getItem("type")}?from=${this.$route.query.from}`)
                                        }
                                        // window.location.replace(`/home_marriage_test/payment/succeed/${this.$route.params.id}/${localStorage.getItem("type")}?from=${this.$route.query.from}`)
                                    }
                                });
                            });
                            if ( this.wechat_pay_key === 1 ) {
                                this.shopPayConfirm = false
                            }else{
                                this.shopPayConfirm = true
                            }
                            this.wechat_pay_key++
                        })
					} else {
                        // console.log('payment_type',payment_type)
						location.href = `https://api.zhiyileiju.cn/api/v1/payment/${payment_type === 'wechat'? 'wechathy' :payment_type}/${this.$route.params.id}/${localStorage.getItem("is_hun_yin_").substr(1)}`;
                        this.shopPayConfirm = true
					}
                }
            });
            

        },
        timimgToast () {//每过5秒弹出用户支付的信息
            let num = Math.floor(Math.random()* this.data_list.length);
            if ( this.data_list.length !== 0) {
                let timer_payment = setInterval( () => {//没过5秒弹出用户购买记录
                    this.$toast({
                        // message:  this.data_list[num].name +'支付了'+ this.data_list[num].price+ "元" +this.data_list[num].designation,
                        message:  this.data_list[num].name +'支付了'+ this.price.marriage_amount + "元" +this.data_list[num].designation,
                        position: 'bottom',
                    });
                },5000)
                localStorage.setItem("timer_payment",timer_payment);
            }
        },
        //确认付款弹窗
        paymentButtoms() {//弹框的立即付款
            switch ('/'+this.$route.path.split('/')[1]) { //埋点
                case '/home_marriage_test':
                    $stat("v1_marry_payment_immediate_payment")
                    break; 
                case '/home_marriage_test_v2':
                    $stat("v2_marry_payment_immediate_payment")
                    break;
                case '/home_marriage_test_v3':
                    $stat("v3_marry_payment_immediate_payment")
                    break;
            
                default:
                    break;
            }
            this.paymentButtom('')
        },
        Dcheckorder () { //已经付款buttom
            switch ('/'+this.$route.path.split('/')[1]) { //埋点
                case '/home_marriage_test':
                    $stat("v1_marry_payment_account_paid")
                    break; 
                case '/home_marriage_test_v2':
                    $stat("v2_marry_payment_account_paid")
                    break;
                case '/home_marriage_test_v3':
                    $stat("v3_marry_payment_account_paid")
                    break;
            
                default:
                    break;
            }
            if ( this.$route.params.type !== '10' ){
                // console.log('type !== 10')
                $paymentQuery({ paytype: this.$route.params.type,order_id: this.$route.params.id,}).then( (res) => {
                    this.is_pay = res.data.data.is_pay
                    if( this.is_pay ) {
                        let arr_from_ = ['hytonp1','nptohy1','kppd','cdpd','kphy','cdhy']
                        if( arr_from_.indexOf(this.$route.query.from) === -1 ) {
                            window.location.replace(`/home_marriage_test/payment/succeed/${this.$route.params.id}/${localStorage.getItem("type")}?from=${this.$route.query.from}`)
                        }else{
                            //直接跳结果
                            window.location.replace(`/home_marriage_test/payment/result/${this.$route.params.id}/${localStorage.getItem("type")}?from=${this.$route.query.from}`)
                        }
                        
                        // window.location.replace(`/home_marriage_test/payment/succeed/${this.$route.params.id}/${localStorage.getItem("type")}?from=${this.$route.query.from}`)
                    }else{
                        // console.log('this.is_pay === false')
                        this.paymentButtom('')
                        // this.$toast.fail("当前订单未支付");
                    }
                } ).catch ( ( err ) => {
                    // console.log('接口请求错误')
                    this.paymentButtom('')
                })
            }
           
        },
        unpay () {//领取红包
            localStorage.setItem("judge_indicates_which",localStorage.getItem('red_packet_type'));
            switch ('/'+this.$route.path.split('/')[1]) { //埋点
                case '/home_marriage_test':
                    $stat("v1_marry_payment_non")
                    break; 
                case '/home_marriage_test_v2':
                    $stat("v2_marry_payment_non")
                    break;
                case '/home_marriage_test_v3':
                    $stat("v3_marry_payment_non")
                    break;
            
                default:
                    break;
            }
            // this.shopPayConfirm = false
            if ( localStorage.getItem('red_packet_type') === '2' ) {
                window.localStorage.removeItem('red_packet_type');
                window.sessionStorage.removeItem('user_id');
                // window.location.replace(`/name_matching?from=w3pd`)
                // if (this.is_weixn) {
                //     let from_arr_ = ['dbhy','xfhy','xfhy2','zbhy','rmhy1','rmhy2','dghy'];
                //     if ( from_arr_.indexOf(this.$route.query.from) === -1) {
                //         window.location.replace(`https://wechat.nnabc.cn/api/v1/wechat/entry/${localStorage.getItem("fromk")}/3`)
                //     }else{
                //         window.location.replace(`https://jx2.gyqingqing.com/wechattestname?fromk=pjgw3cm`)
                //     }
                // }else{
                //     window.location.replace(`/name_matching?from=w3pd`)
                // }
               this.saveTheLinkCallback()
            }else{
               
                this.$router.push('/'+this.$route.path.split('/')[1])

            }
            // this.$router.push(`${localStorage.getItem("is_hun_yin_")}`)
            // this.$router.push(`${localStorage.getItem("is_hun_yin_")}fromk=?${localStorage.getItem("fromk")}`)
        },
        complaintButtom(){//投诉
            switch ('/'+this.$route.path.split('/')[1]) { //埋点
                case '/home_marriage_test':
                    $stat("v1_marry_payment_complaint")
                    break; 
                case '/home_marriage_test_v2':
                    $stat("v2_marry_payment_complaint")
                    break;
                case '/home_marriage_test_v3':
                    $stat("v3_marry_payment_complaint")
                    break;
            
                default:
                    break;
            }
            window.location.href = 'https://www.wjx.top/vm/hd0JNnc.aspx'
        },
    },
    destroyed () {
        window.removeEventListener('popstate', this.goBack, false);
    },
}

export default payment 
